import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const AboutTabs = () => {
  return (
    <>
      <Tabs>
        <TabList className="nav nav-tabs md-mt-50">
          {/* <Tab className="nav-link font-slab">Our Story</Tab> */}
          <Tab className="nav-link font-slab">Our Mission</Tab>
          {/* <Tab className="nav-link font-slab">Our Vision</Tab> */}
        </TabList>
        {/* End TabList */}

        <div className="tab-content mt-20">
          <TabPanel>
            <p className="font-rubik">
                           We work closely with nutritionists and midwives to create balanced, nutritious, and healthy recipes that help parents introduce their baby to solid food.
            </p>
            <p className="font-rubik">
Convenience and time efficiency are our top priorities. That's why we offer a one-click process that enables parents to jump from recipe selection to the final ordering process for all ingredients in just a few minutes.
 </p> <p className="font-rubik">
We not only assist parents with meal planning but also provide important guidance and education on the introduction of solid foods, allergens, and nutritional challenges.


            </p>
          </TabPanel>
          {/* <TabPanel>
            <p className="font-rubik">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
            <p className="font-rubik">
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
              professor.
            </p>
          </TabPanel>
          <TabPanel>
            <p className="font-rubik">
              There are many variations of passages of Lorem Ipsum available,
              but the majority,
            </p>
            <p className="font-rubik">
              Many desktop publishing packages and web page editors now use
              Lorem Ipsum as their default model text, and a search for 'lorem
              ipsum' will uncover many web sites still in their infancy.
            </p>
          </TabPanel> */}
        </div>
        {/* End TabContent */}
      </Tabs>
    </>
  );
};

export default AboutTabs;
