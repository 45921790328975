import React, { useState } from "react";
import { useQuery } from 'react-query'

import {useRouter} from "../../hooks/utils";
import api from '../../services/api/api';
import Steps from "../steps/Steps";
import ListOptions from "../list-options/ListOptions";

const checks = [
  {
    "title": "Head and neck control",
    "description": "Your baby can hold the head up steadily and sit upright with minimal support."
  },
  {
    "title": "Tongue thrust reflex",
    "description": "The reflex that causes babies to push objects out of their mouths using their tongues has diminished with your baby."
  },
  {
    "title": "Interest in food",
    "description": "Your baby is interested in food and shows curiosity while you're eating."
  },
  {
    "title": "Increased appetite",
    "description": "Your baby has a demand for more frequent feedings."
  },
  {
    "title": "Ability to chew or gnaw",
    "description": "Your baby has started demonstrating chewing motions, even without teeth."
  }
]

const startOptions = [
    {
    id: 1,
    name: "Yes"
  },
   {
    id: -1,
    name: "No"
  }
]


export default function SlickAsNav() {
  const [startAnswer, setStartAnswer] = useState();
  const [step, setStep] = useState(0);
  const [phase, setPhase] = useState();
  const [form, setForm] = useState();
  const [intolerance, setIntolerance] = useState([]);
  const router = useRouter()


  const phasesData = useQuery(['phases'], api.requests.phases.getPhaseList);
  const formsData = useQuery(['forms'], api.requests.forms.getFormList);
  const allergensData = useQuery(['allergens'], api.requests.allergens.getAllergenList);

  const phases = phasesData?.data?.data?.data?.documents
  const forms = formsData?.data?.data?.data?.documents
  const allergens = allergensData?.data?.data?.data?.documents


  const onSelect = (id) => {
    switch (step) {
      case 0:
         setStartAnswer(id);
      case 1:
         setPhase(id);
        break;  
      case 2:
         setForm(id);   
          break;
      case 3:
        const newItems = intolerance.includes(id) ? intolerance.filter(i => i !== id) :  [...intolerance, id]
         setIntolerance(newItems);
        break;
      default:
        break;
    }
  }

  const renderListOptions = () => {
    switch (step) {
      case -1: 
      return renderChecks();
      case 0:
        return <ListOptions idLabel="id" titleLabel="name" selectedItemIds={[startAnswer]} items={startOptions} onSelect={onSelect}/>    
      case 1:
        return phases?.length && <ListOptions idLabel="_id" titleLabel="name" selectedItemIds={[phase]} items={phases} onSelect={onSelect}/>    
    case 2:
        return forms?.length && <ListOptions idLabel="_id" titleLabel="name" selectedItemIds={[form]} items={forms} onSelect={onSelect}/>
    case 3:
        return allergens?.length && <ListOptions idLabel="_id" titleLabel="name" selectedItemIds={intolerance} items={allergens} onSelect={onSelect}/>
    
      default:
        break;
    }
  }


  const renderNextButton = () => {
    switch (step) {
      case -1:
        return <button href="#" className="shop-btn-two tran3s" onClick={() => setStep(1)}>
          My baby is ready!
        </button> 
      case 0:
        return startAnswer && <button href="#" className="shop-btn-two tran3s" onClick={() => setStep(startAnswer)}>
          Next
        </button> 
      case 1:
        return phase && <button href="#" className="shop-btn-two tran3s" onClick={() => setStep(2)}>
          Next
        </button> 
      case 2:
        return form && <button href="#" className="shop-btn-two tran3s" onClick={() => setStep(3)}>
          Next
        </button>  
      case 3:
        return intolerance && <button href="#" className="shop-btn-two tran3s" onClick={() => router.push("/recipes", {
          phases: [phase],
          forms: [form],
          allergens: intolerance
        })}>
          Save
        </button>
      default:
        break;
    }
  }
  
  const renderPrevButton = () => {
    switch (step) {
      case 2:
        return <button href="#" className="shop-btn-two tran3s" onClick={() => setStep(1)}>
          Previous
        </button>
      case 3:
        return <button href="#" className="shop-btn-two tran3s" onClick={() => setStep(2)}>
          Previous
        </button>
    
      default:
        return <div/>
    }
  }

  const renderChecks = () => {
    return <div className="list-options-container"><div className="list-options"><ol className="style-none product-feature">
            {checks.map((item, index) => {
                  return  <li key={index}>
                    <b>{item.title}</b>: {item.description}
                  </li>
                })}
                </ol>
                </div> </div>
  }

  return (
    <>
      <Steps activeStep={step}/>
        {renderListOptions()}
      <div className="step-buttons-container">
        {renderPrevButton()}
        {renderNextButton()}
      </div>
    </>
  );
}
