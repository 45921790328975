import { configureStore, combineReducers } from '@reduxjs/toolkit'
import cartReducer from './slices/cartSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const rootReducer = combineReducers({
    cart: cartReducer
})

const persistConfig = {
  key: 'root',
  storage,
  version: 1
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const init = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({
        serializableCheck: false
      });
      return middleware;
    },
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default init;