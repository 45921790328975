import React, {useState} from "react";
import { Helmet } from "react-helmet";
import { useQuery } from 'react-query'
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import { Gallery, Item } from "react-photoswipe-gallery";

import { addMultipleItemsToCart, setCartVisibility } from '../../../store/slices/cartSlice';
import { selectCartVisibility} from '../../../store/selectors/cart';
import Quantity from "../../../components/e-commerce/product-details/Quantity";
import Header from "../../../components/e-commerce/Header";
import FooterEight from "../../../components/footer/FooterEight";
import {useRouter} from "../../../hooks/utils";
import api from '../../../services/api/api';

const ProductDetails = () => {
  const dispatch = useDispatch();
  const router = useRouter()
  const [quantity, setQuantity] = useState(1);
  const recipeId = router.params.id
  
  const recipeData = useQuery(['recipes', recipeId], () => api.requests.recipes.getSingleRecipe(recipeId));
  const recipe = recipeData?.data?.data?.data?.document;
    const isCartVisible = useSelector(selectCartVisibility);

  console.log("recipe => ", recipe);

    const handleAddToCart = () => {
    const newItems = recipe.ingredients?.length && recipe.ingredients.map(item => {
      return {
        ...item,
        amount: item.amount * quantity
      }
    });

    dispatch(addMultipleItemsToCart(newItems));

    if (!isCartVisible) {
        dispatch(setCartVisibility(true));
    }

  };

  const onQuantityChange = (e) => {
    if (e.target.value) {
      setQuantity(parseInt(e.target.value))
    }
  }

  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Recipe Details</title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* <!-- 
      =============================================
      Theme Main Menu
      ============================================== 
      --> */}
      <Header />

      {/* <!-- 
        =============================================
        Product Details One
        ============================================== 
        --> */}
      <div className="product-details-one lg-container pt-180 lg-pt-150">
        {/* <div className="breadcrumb-area pb-70">
          <div className="container">
            <div className="d-sm-flex align-items-center justify-content-between">
              <BreadCrumb />
              <SocialShare />
            </div>
          </div>
        </div> */}
        {/* <!-- /.breadcrumb-area --> */}

        <div className="container">
              <Gallery>
      <Tabs>
        <div className="row">
          <div className="col-lg-5 order-lg-2">
            <div className=" product-img-tab-content">
              <TabPanel>
                <div>
                  <Item
                    original={recipe?.image}
                    thumbnail={recipe?.image}
             
                  >
                    {({ ref, open }) => (
                      <div className="fancybox h-100 w-100 d-flex align-items-center justify-content-center">
                        <img
                          src={recipe?.image}
                          alt="Gallery"
                          className="m-auto"
                          role="button"
                          ref={ref}
                          onClick={open}
                        />
                      </div>
                    )}
                  </Item>
                </div>
              </TabPanel>
              {/* End tabpanel */}

              {/* <TabPanel>
                <div className="h-100">
                  <Item
                    original="images/shop/img_21.png"
                    thumbnail="images/shop/img_21.png"
                    width={533}
                    height={611}
                  >
                    {({ ref, open }) => (
                      <div className="fancybox h-100 w-100 d-flex align-items-center justify-content-center">
                        <img
                          src="images/shop/img_21.png"
                          alt="Gallery"
                          className="m-auto"
                          role="button"
                          ref={ref}
                          onClick={open}
                        />
                      </div>
                    )}
                  </Item>
                </div>
              </TabPanel> */}
              {/* End tabpanel */}

              {/* <TabPanel>
                <div className="h-100">
                  <Item
                    original="images/shop/img_22.png"
                    thumbnail="images/shop/img_22.png"
                    width={395}
                    height={588}
                  >
                    {({ ref, open }) => (
                      <div className="fancybox h-100 w-100 d-flex align-items-center justify-content-center">
                        <img
                          src="images/shop/img_22.png"
                          alt="Gallery"
                          className="m-auto"
                          role="button"
                          ref={ref}
                          onClick={open}
                        />
                      </div>
                    )}
                  </Item>
                </div>
              </TabPanel> */}
            </div>
          </div>
          {/* End larget gallery */}

          <div className="col-lg-1 order-lg-1">
            <TabList className="nav nav-tabs flex-lg-column product-img-tab">
              <Tab className="nav-item">
                <button className="nav-link">
                  <img
                    src={recipe?.image}
                    alt="shop "
                    className="m-auto"
                  />
                </button>
              </Tab>
              {/* <Tab className="nav-item">
                <button className="nav-link">
                  <img
                    src="images/shop/img_18.png"
                    alt="shop "
                    className="m-auto"
                  />
                </button>
              </Tab>
              <Tab className="nav-item">
                <button className="nav-link">
                  <img
                    src="images/shop/img_19.png"
                    alt="shop "
                    className="m-auto"
                  />
                </button>
              </Tab> */}
            </TabList>
          </div>
          {/* End thumb gallery */}

          <div className="col-lg-6 order-lg-3">
             <div className="product-info ps-xl-5 md-mt-50">
      {/* <div className="stock-tag">In Stock</div> */}
      <h3 className="product-name">{recipe?.name}</h3>
      <ul className="style-none d-flex align-items-center rating">
        <li>
          <i className="fa fa-star" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-star" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-star" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-star" aria-hidden="true"></i>
        </li>
        <li>
          <i className="fa fa-star-o" aria-hidden="true"></i>
        </li>
        <li>
          <a href="#">(2 Customer Reviews)</a>
        </li>
      </ul>
      {/* <div className="price">$49.00 - $53.00</div> */}
      {/* <p className="availability">38 Piece Available </p> */}
      <p className="description-text">
        {recipe?.description}{" "}
      </p>
      <ul className="product-feature style-none">
        <li>{recipe?.phase?.name}</li>
        <li>{recipe?.form?.name}</li>
      </ul>

      <div className="customize-order">
        <div className="row">
          <div className="col-xl-11">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-4">
                <div className="quantity mt-25">
                  <h6>Portion</h6>
                  <Quantity 
                        value={quantity}
        onMinus={() => quantity > 0 ? setQuantity(quantity - 1) : null}
        onPlus={() => quantity < 10 ? setQuantity(quantity + 1) : null}
        onChange={onQuantityChange}
                  />
                </div>
              </div>
              {/* End Quantity */}

              {/* <div className="col-lg-3 col-md-3 col-sm-4">
                <div className="color-selection mt-25">
                  <h6>Colors</h6>
                  <Colors />
                </div>
              </div> */}
              {/* End colors  */}

              {/* <div className="col-xl-5 col-md-6 col-sm-4">
                <div className="size-selection mt-25">
                  <h6>Size</h6>
                  <Size />
                </div>
              </div> */}
              {/* End Size */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.customize-order --> */}

      <div className="button-group mt-30 d-sm-flex align-items-center">
        <Link onClick={handleAddToCart} className="cart-btn mt-15 me-sm-4 d-block">
          Add To Cart
        </Link>
        {/* <a href="#" className="wishlist-btn mt-15 d-block">
          Add To wishlist
        </a> */}
      </div>
    </div>
          </div>
          {/* End product gallery content */}
        </div>
      </Tabs>
    </Gallery>
          <div className="product-review-tab mt-150 lg-mt-100">
      <Tabs>
        <TabList className="nav nav-tabs">
          <Tab className="nav-item">
            <button className="nav-link">PREPARATION</button>
          </Tab>
          <Tab className="nav-item">
            <button className="nav-link ">Allergens</button>
          </Tab>
          <Tab className="nav-item">
            <button className="nav-link "> Feedback</button>
          </Tab>
        </TabList>

        <div className="tab-content mt-40 lg-mt-20">
          <TabPanel>
            <div className="row gx-5">
            <div className="col-xl-6">
                <h5>Steps:</h5>
              
                <ul className="style-none product-feature">
                    {recipe?.preparation?.length && recipe?.preparation.map((item, index) => {
                  return  <li key={index}>
                    Step {item.step}: {item.description}
                  </li>
                })}
                </ul>
              </div>
              <div className="col-xl-6">
                <h5>Ingredients:</h5>
                   <ul className="style-none">
                {recipe?.ingredients?.length && recipe?.ingredients.map(item => {
                  return <li key={item._id}>
                    {item.ingredient?.name}: {item.amount}{item.ingredient?.unit} ({item.amount}x{item.ingredient?.quantity}{item.ingredient?.measurement})
                  </li>
                })}
                 </ul>
                {/* <table>
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                
                {recipe?.ingredients?.length && recipe?.ingredients.map(item => {
                  return <tr key={item._id}>
                    <td>{item.ingredient?.name}</td>
                    <td>{item.amount}</td>
                  </tr>
                })}
              
                 </tbody>
                </table> */}
                {/* <p>
                  One touch of a red-hot stove is usually all we need to avoid
                  that . The same is true as we experience in emotional
                  sensation of stress from our first instances of social
                  rejection ridicule.
                </p> */}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="row gx-5">
              <div className="col-xl-6">
                <h5>The recipe contains following allergens:</h5>
                
                <ul className="style-none product-feature">
                  {recipe?.allergens?.length && recipe?.allergens.map(item => {
                  return <li key={item._id}>
                    {item.name}: {item.description}
                  </li>
                })}
                </ul>
              </div>
              {/* <div className="col-xl-6">
                <h5>Specifications:</h5>
                <p>
                  One touch of a red-hot stove is usually all we need to avoid
                  that . The same is true as we experience in emotional
                  sensation of stress from our first instances of social
                  rejection ridicule.
                </p>
              </div> */}
            </div>
          </TabPanel>
          <TabPanel>
            <div className="user-comment-area">
              <div className="single-comment d-flex align-items-top">
                <img
                  src="images/shop/avatar_01.jpg"
                  alt=""
                  className="user-img"
                />
                <div className="user-comment-data">
                  <h6 className="name">Rashed ka.</h6>
                  <ul className="style-none d-flex rating">
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                    </li>
                  </ul>
                  <p>
                    One touch of a red-hot stove is usually all we need to avoid
                    that kind of discomfort in future. The same true we
                    experience the emotional sensation.
                  </p>
                </div>
                {/* <!-- /.user-comment-data --> */}
              </div>
              {/* <!-- /.single-comment --> */}
              <div className="single-comment d-flex align-items-top">
                <img
                  src="images/shop/avatar_02.jpg"
                  alt=""
                  className="user-img"
                />
                <div className="user-comment-data">
                  <h6 className="name">Zubayer hasan</h6>
                  <ul className="style-none d-flex rating">
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star" aria-hidden="true"></i>
                    </li>
                    <li>
                      <i className="fa fa-star-o" aria-hidden="true"></i>
                    </li>
                  </ul>
                  <p>
                    One touch of a red-hot stove is usually all we need to avoid
                    that kind of discomfort in future. The same true we
                    experience the emotional sensation.
                  </p>
                </div>
                {/* <!-- /.user-comment-data --> */}
              </div>
              {/* <!-- /.single-comment --> */}
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </div>
          {/* <!-- /.product-review-tab --> */}
          {/* <div className="mt-60 lg-mt-40">
            <div className="row">
              <ProductFratureBlock />
            </div>
          </div> */}
        </div>
        {/* End .container */}
      </div>
      {/* <!-- /.product-details-one --> */}

      {/* <!-- 
        =============================================
            Fancy Feature Forty Three
        ============================================== 
        --> */}
      {/* <div className="fancy-feature-fortyThree lg-container pt-100 pb-100 mt-130 mb-120 lg-pt-80 lg-pb-80 lg-mt-100 lg-mb-100">
        <div className="container">
          <div className="row align-items-center" data-aos="fade-up">
            <div className="col-lg-6">
              <div className="title-style-eleven text-center text-md-left">
                <h2>Releted Products</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="xl-container mt-60 lg-mt-40">
          <div className="product_slider_two product_slider_space">
            <RelatedProducts />
          </div>
        </div>
      </div> */}
      {/* <!-- /.fancy-feature-fortyThree --> */}

      {/* =====================================================
        Footer Style Seven
        ===================================================== */}
        <br/>
        <hr/>
      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>
    </div>
    // main-page-wrapper
  );
};

export default ProductDetails;
