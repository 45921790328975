import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSeven() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [ {
      img: "person-4",
      desc: ` As a busy working parent, meal planning and preparation for my baby has been a major stressor. I often find myself resorting to quick and less nutritious options due to time constraints. I would love a convenient and reliable solution that ensures my baby gets the nourishment they need.`,
      reviewerName: "Michael",
      country: "Germany",
      bgColor: "#8F6BF6",
    },
    {
      img: "person-3",
      desc: `The baby feeding was the first thing that was really difficult. I'm actually a relaxed mum, but then I became unrelaxed because a lot of outside opinions were forced on me.`,
      reviewerName: "Lena",
      country: "Germany",
      bgColor: "#FF47E2",
    },
    {
      img: "person-2",
      desc: `I would like to have more good and healthy recipes that the child can eat with us. That way we don't always have to cook extra and we safe time..`,
      reviewerName: "Markus",
      country: "Germany",
      bgColor: "#00E5F3",
    },
    {
      img: "person-1",
      desc: ` I would like to have more structure when it comes to my child's first meal, a plan that I can stick to but that also allows for deviations, because every child is different.`,
      reviewerName: "Lisa",
      country: "Germany",
      bgColor: "#FFCF24",
    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="feedback-wrapper">
            <span className="ribbon" style={{ background: val.bgColor }}></span>
            <p>{val.desc}</p>
            <div className="d-flex align-items-center">
              <img
                src={`images/media/${val.img}.jpg`}
                alt="icon"
                className="avatar"
              />
              <h6 className="name">
                {val.reviewerName},<span>{" "}{val.country}</span>
              </h6>
            </div>
          </div>
          {/* End .feedback-wrapper */}
        </div>
      ))}
    </Slider>
  );
}
