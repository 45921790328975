import React from "react";
import { Helmet } from "react-helmet";
import { useQuery } from 'react-query'

import Header from "../../../components/e-commerce/Header";
import BlogStyle2 from "../../../components/blog/BlogStyle2";
import FooterEight from "../../../components/footer/FooterEight";
import CopyRight from "../../../components/footer/CopyRight";
import BlogSidebar from "../../../components/blog/BlogSidebar";
import api from '../../../services/api/api';
import {useRouter} from "../../../hooks/utils";

const BlogV2 = () => {
  const { push, params } = useRouter();
  const blogsData = useQuery(['blogs', params.page], () => api.requests.blogs.getBlogList(params));
  const blogs = blogsData?.data?.data?.data?.documents
  const totalPages = blogsData?.data?.data?.totalPages


  const activePageClassName = (page) => {
    console.log("page");
    console.log("page => ", );
     if (!params.page && page === 1) {
        return "active"
      }

      if (params.page && parseInt(params.page) === page) {
        return "active"
      }

      return "";
  }

  return (
    <div className="main-page-wrapper">
      <Header/>
      {/* 	=============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <h2 className="font-rubik">Nourishing Little Tummies</h2>
            </div>
            <div className="col-lg-9 m-auto">
              <p className="font-rubik">
                Expert Tips and Wholesome Recipes for Your Baby
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 feature-blog-one width-lg">

              {blogs?.length ? blogs.map(({_id, title, image, intro}) => {
                return   <BlogStyle2 key={_id} id={_id} title={title} image={image} intro={intro}/>
              }) : null} 
            
              <div className="page-pagination-one pt-15">
                <ul className="d-flex align-items-center">
                  {/* <li>
                    <a href="#" onClick={() => push(`/blog`, {page: params.page && parseInt(params.page) - 1})}>
                      <i className="fa fa-angle-left"></i>
                    </a>
                  </li> */}
               {
                     totalPages && Array.from({ length: parseInt(totalPages) }, (num, i) => {
                      const page = i + 1;

                        return <li key={i}>
                            <a href="#" onClick={() => push(`/blog`, {page})} className={activePageClassName(page)}>
                              {page}
                            </a>
                          </li>
                     })
                  }

                  {/* <li> &nbsp; ... &nbsp;</li> */}
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-angle-right"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
              {/* /.page-pagination-one */}
            </div>
            {/* End .col */}
            <div className="col-lg-4 col-md-6">
              <BlogSidebar blogs={blogs}/>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogV2;
