import React from "react";

import DocSlickAsNav from "../../components/block-slider/DocSlickAsNav";

const Setup = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <div className="fancy-feature-fortyTwo lg-container pt-180 lg-pt-120">
        <div className="container">
          <div className="row align-items-center" data-aos="fade-up">
            <div className="fancy-text-block-sixteen mt-200 md-mt-80">
                    <div className="container">
                    <DocSlickAsNav />
                    </div>
                </div>

    
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
        {/* End .container */}
        {/* <!-- /.xl-container --> */}
      </div>
    </div>
    // End main-page-wrapper
  );
};

export default Setup;
