
import classNames from "classnames";

const data = [
    {
        id: -1,
        number: "",
        title: "Determine Your Baby's Readiness for Solid Food",
        question: "It's important to find out if your baby is ready to have solid food. You can check this in 5 steps:"
    },
    {
        id: 0,
        number: "",
        title: "Let's start!",
        question: "Have you already started introducing your baby to solid food?"
    },
    {
        id: 1,
        number: "01",
        title: "Feeding Stage",
        question: "For how long is your baby eating solid food?"
    },
    {
        id: 2,
        number: "02",
        title: "Nutrition Form",
        question: "Do you want to give your baby mashed food, finger food (baby-led weaning) or mixed?"
    },
    {   id: 3,
        number: "03",
        title: "Intolerance",
        question: "Does your baby have any intolerance?"
    },
]

const Steps = ({activeStep}) => {
    const item = data.find(i => i.id === activeStep);

    return  <div className="steps-container">
        <div className="steps-progress-container"><div className="steps-progress" style={{width:  `${activeStep / data.filter(item => item.id > 0).length * 100}%` }}/></div>
            <div className={classNames('block-style-eleven step-item', { "step-active": activeStep === item.id }) }>
            <div className="step-inner">
                <div className="num font-rubik">{item.number}</div>
                <div className="title">{item.title}</div>
                <p className="font-rubik">
                    {item.question}
                </p>
            </div>
        </div>
    </div>

    // <div className="steps-container">
    //     <div className={classNames('block-style-eleven step-item mt-40', { "step-active": true }) }>
    //         <div className="step-inner">
    //             <div className="num font-rubik">01</div>
    //             <div className="title">Feeding Stage</div>
    //             <p className="font-rubik">
    //                 For how long is your baby eating solid food?
    //             </p>
    //         </div>
    //     </div>
    //     <div className="block-style-eleven step-item mt-40">
    //         <div className="step-inner">
    //       <div className="num font-rubik">02</div>
    //       <div className="title">Nutrition Form</div>
    //       <p className="font-rubik">
    //         Do you want to give your baby mashed food, finger food (baby-led weaning) or mixed?
    //       </p>
    //       </div>
    //     </div>
    //     <div className="block-style-eleven step-item mt-40">
    //         <div className="step-inner">
    //       <div className="num font-rubik">03</div>
    //       <div className="title">Intolerance</div>
    //       <p className="font-rubik">
    //        Does your baby have any intolerance?
    //       </p>
    //       </div>
    //     </div>
    // </div>
}

export default Steps;