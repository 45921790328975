import React, { useState } from 'react';
import { useQuery } from 'react-query'

import { useRouter } from '../../hooks/utils';
import api from '../../services/api/api';

const Filters = () => {
//   const [selectedItems, setSelectedItems] = useState({});
  const { params, push } = useRouter();

const phasesData = useQuery(['phases'], api.requests.phases.getPhaseList);
  const formsData = useQuery(['forms'], api.requests.forms.getFormList);
  const allergensData = useQuery(['allergens'], api.requests.allergens.getAllergenList);
  const ingredientsData = useQuery(['ingredients'], api.requests.ingredients.getIngredientList);

  const phases = phasesData?.data?.data?.data?.documents
  const forms = formsData?.data?.data?.data?.documents
  const allergens = allergensData?.data?.data?.data?.documents
  const ingredients = ingredientsData?.data?.data?.data?.documents

    // console.log("selectedItems => ", selectedItems);
//   console.log("form => ", forms);
//   console.log("intolerance => ", allergens);


//   const handleCheckboxChange = (category, itemId) => {
//     setSelectedItems((prevSelectedItems) => {
//       const updatedSelectedItems = { ...prevSelectedItems };

//       if (updatedSelectedItems[category]) {
//         if (updatedSelectedItems[category].includes(itemId)) {
//           updatedSelectedItems[category] = updatedSelectedItems[category].filter(
//             (item) => item !== itemId
//           );
//         } else {
//           updatedSelectedItems[category].push(itemId);
//         }
//       } else {
//         updatedSelectedItems[category] = [itemId];
//       }

//       return updatedSelectedItems;
//     });
//   };


const handleCheckboxChange = (category, itemId) => {
    const updatedSelectedItems = { ...params };

    if (updatedSelectedItems[category]) {
      if (updatedSelectedItems[category].includes(itemId)) {
        updatedSelectedItems[category] = updatedSelectedItems[category].filter(
          (item) => item !== itemId
        );
      } else {
        updatedSelectedItems[category].push(itemId);
      }
    } else {
      updatedSelectedItems[category] = [itemId];
    }

    // update the query parameters
    push(window.location.pathname, updatedSelectedItems);
  };



  return (
   <div className="category-filters">
        <div className="category-section">
          <h5 className="category-title">Phase</h5>
          <ul className="category-items">
            {phases?.length && phases.map((item) => (
              <li className="category-item" key={item._id}>
                <label>
                  <input
                    type="checkbox"
                      checked={
                  params["phases"] ?
                  params["phases"].includes(item._id) : false
                }
                    onChange={(e) =>
                      handleCheckboxChange("phases", item._id, e.target.checked)
                    }
                  />
                  {item.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
         <div className="category-section">
            <hr />
          <h5 className="category-title">Nutrition Form</h5>
          <ul className="category-items">
            {forms?.length && forms.map((item) => (
              <li className="category-item" key={item._id}>
                <label>
                  <input
                    type="checkbox"
                      checked={
                  params["forms"] ?
                  params["forms"].includes(item._id) : false
                }
                    onChange={(e) =>
                      handleCheckboxChange("forms", item._id, e.target.checked)
                    }
                  />
                  {item.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
           <div className="category-section">
            <hr />
          <h5 className="category-title">Excluded Allergens</h5>
          <ul className="category-items">
            {allergens?.length && allergens.map((item) => (
              <li className="category-item" key={item._id}>
                <label>
                  <input
                    type="checkbox"
                      checked={
                  params["allergens"] ?
                  params["allergens"].includes(item._id) : false
                }
                    onChange={(e) =>
                      handleCheckboxChange("allergens", item._id, e.target.checked)
                    }
                  />
                  {item.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
         <div className="category-section">
            <hr />
          <h5 className="category-title">Excluded Ingredients</h5>
          <ul className="category-items">
            {ingredients?.length && ingredients.map((item) => (
              <li className="category-item" key={item._id}>
                <label>
                  <input
                    type="checkbox"
                      checked={
                  params["ingredients"] ?
                  params["ingredients"].includes(item._id) : false
                }
                    onChange={(e) =>
                      handleCheckboxChange("ingredients", item._id, e.target.checked)
                    }
                  />
                  {item.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
    </div>
  );
};

export default Filters;