import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";

import FancyFeatureTewentyFour from "../../components/features/FancyFeatureTewentyFour";
import FancyFeatureTewentyTwo from "../../components/features/FancyFeatureTewentyTwo";
import HeaderLandingAppointment from "../../components/header/landing/HeaderLandingAppointment";
import HeroBannerNine from "../../components/hero-banner/HeroBannerNine";
import TestimonialSeven from "../../components/testimonial/TestimonialSeven";
import SocialTwo from "../../components/social/SocialTwo";
import FooterEight from "../../components/footer/FooterEight";
import CopyRightFour from "../../components/footer/CopyRightFour";
import CallToActionFour from "../../components/call-to-action/CallToActionFour";
import CallToActionTwo from "../../components/call-to-action/CallToActionTwo";
import Header from "../../components/e-commerce/Header";
import FaqClassic from "../../components/faq/FaqClassicTwo";

const AppointmentScheduling = () => {
  // For header select menu
  // const [click1, setClick1] = useState(false);
  // const handleClick1 = () => setClick1(!click1);

  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>
          REKAPD - Transforming Baby Mealtime with Convenience and Nutrition
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* <HeaderLandingAppointment /> */}
      <Header/>
      {/* End Header Landing Appoinment Scheduling */}

      <div id="home">
        <HeroBannerNine />
      </div>
      {/* End Hero Banner Nine */}

      {/* =============================================
            Fancy Feature Twenty Two
        ==============================================  */}
      <div className="fancy-feature-twentyTwo mt-200 md-mt-120">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-7 col-md-8 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-eight text-center mb-40 md-mb-10">
                <h2>
                  The Challenges of Baby{" "}
                  <span>
                     Mealtime<img src="images/shape/191.svg" alt="shape" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <FancyFeatureTewentyTwo />
          </div>
        </div>
      </div>
      {/* /.fancy-feature-twentyTwo */}

      {/* =============================================
            Fancy Feature Twenty Three
        ==============================================  */}
      <div className="fancy-feature-twentyThree pt-170 md-pt-100" id="feature">
        <div className="container">
          <div
            className="title-style-nine text-center pb-180 md-pb-100"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h6>Our Features</h6>
            <h2>
               Simplifying Baby Mealtime with Convenience, Nutrition, and{" "}
              <span>
                Personalization <img src="images/shape/192.svg" alt="shape" />
              </span>
            </h2>
            <p>
              Our innovative platform empowers you to effortlessly nourish your baby with customized, nutritious meals for their optimal growth and development.
            </p>
          </div>
          {/* End title */}

          <div className="block-style-twentyThree">
            <div className="row align-items-center">
              <div
                className="col-lg-6 order-lg-last ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                    className="oval-shape"
                    style={{ background: "#69FF9C" }}
                  ></div>
                  <div
                    className="oval-shape"
                    style={{ background: "#FFF170" }}
                  ></div>
                  <img
                    src="images/assets/one-click.png"
                    alt=""
                    className="shapes shape-one"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Simplified Ordering</h6>
                  <h3 className="title">One-Click Meal Selection.</h3>
                  <p>
                   Easily browse our diverse menu and customize your baby's meals with just a single click. Take the hassle out of meal planning and order with simplicity and convenience.
                   <br />
                    <a href="/feature-1">Order with a click!</a>
                  </p>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="screen-container me-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div
                    className="oval-shape"
                    style={{ background: "#FFDE69" }}
                  ></div>
                  <div
                    className="oval-shape"
                    style={{ background: "#FF77D9" }}
                  ></div>
                  <img
                    src="images/assets/expert.png"
                    alt="screen"
                    className="shapes shape-two"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-lg-5 ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Nutrition Expertise</h6>
                  <h3 className="title">Expert-Crafted Menus.</h3>
                  <p>
                    Trust in the expertise of our nutritionists who curate carefully balanced menus tailored to your baby's specific needs. Rest assured that every meal is designed to provide optimal nutrition for their growth and development.
                    <br />
                      <a href="/feature-2">Experience expert-crafted menus!</a>
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                    className="oval-shape"
                    style={{ background: "#00F0FF" }}
                  ></div>
                  <div
                    className="oval-shape"
                    style={{ background: "#FC6BFF" }}
                  ></div>
                  <img
                    src="images/assets/personal.png"
                    alt="screen"
                    className="shapes shape-three"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-lg-5 order-lg-first"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Flexible Customization</h6>
                  <h3 className="title">
                    Personalize Your Baby's Meals.
                  </h3>
                  <p>
                   Tailor each meal to accommodate your baby's preferences, allergies, and dietary requirements. Enjoy the flexibility to create personalized meals that perfectly align with your little one's unique tastes and needs.
                   <br />
                    <a href="/feature-3">Personalize your baby's meals now!</a>
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}
        </div>
      </div>
      {/* /.fancy-feature-twentyThree */}

      {/* 	=============================================
            Fancy Feature Twenty Four
        ==============================================  */}
      <div className="fancy-feature-twentyFour pt-140 md-pt-60" id="benefits">
        <div className="container">
          <div className="title-style-nine text-center">
            <h6>Why Choose REKAPD?</h6>
            <h2>
              Experience the Transformative Benefits for Your {" "}
              <span>
                Baby and Family <img src="images/shape/194.svg" alt="shape" />
              </span>
            </h2>
          </div>
        </div>
        <div className="bg-wrapper mt-100 md-mt-80">
          <a
            href="#feedback"
            className="click-scroll-button scroll-target d-flex justify-content-center"
          >
            <img src="images/shape/200.svg" alt="shape" />
          </a>
          <div className="container">
            <FancyFeatureTewentyFour />
          </div>
          <img
            src="images/shape/195.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/196.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/197.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/198.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/199.svg"
            alt="shape"
            className="shapes shape-five"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
        <div className="faq-classic pt-225 md-pt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-one">
                {/* <h6 className="font-rubik">Find your answers</h6> */}
                <h2>Expert Tips for Baby's Health and Well-being</h2>
              </div>
              {/* /.title-style-one */}
              <Link to="/tips" className="theme-btn-one mt-50 md-mt-30">
                {" "}
                Go to Tips
              </Link>
            </div>
            {/* End .col */}
            <div className="col-lg-6">
              <div className="md-mt-60">
                <FaqClassic />
              </div>
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* End .fancy feature twenty four */}

      {/* =====================================================
            Client Feedback Slider Six
        ===================================================== */}
      <div
        className="client-feedback-slider-six pt-170 md-pt-120"
        id="insights"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-7 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="title-style-nine text-center">
                  <h6>Testimonials</h6>
                  <h2>
                      
                    <span>
                 Insights<img src="images/shape/201.svg" alt="shape" />
                    </span>{" "}
                    from Interviews
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/* End .container */}
          <div className="clientSliderSix style-two">
            <TestimonialSeven />
          </div>
        </div>
        {/*  /.inner-container */}
      </div>
      {/* /.client-feedback-slider-six */}

       <div className="fancy-short-banner-one" id="cta">
        <div className="container">
          <CallToActionFour />
        </div>
        {/*  /.container */}
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>

      {/*     =====================================================
        Partner Section One
    ===================================================== */}
      <div className="partner-section-one mt-170 md-mt-90 pb-120 md-pb-80">
        <div className="container">
          <div className="title-style-nine text-center mb-100">
            <h6>Our Partners</h6>
            <h2>
              They
              {" "}<span>
                Trust Us <img src="images/shape/201.svg" alt="shape" />
              </span>{" "}
              & Vice Versa
            </h2>
          </div>
          {/* End title-style-nine */}
          <SocialTwo />
        </div>
        {/* End .container */}

        <img
          src="images/shape/202.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/203.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <img
          src="images/shape/204.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/205.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/206.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/207.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src="images/shape/208.svg"
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src="images/shape/209.svg"
          alt="shape"
          className="shapes shape-eight"
        />
      </div>
      {/*  /.partner-section-one */}

      {/* 	=====================================================
				Fancy Short Banner Ten
			===================================================== */}

      {/*  /.fancy-short-banner-ten */}

      {/* <div className="fancy-short-banner-four fixed-form">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionTwo />
          </div>
        </div>
      </div> */}

      {/*  =====================================================
            Footer Style Eight
        ===================================================== */}
      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>
      {/* /.theme-footer-eight  */}
      <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
    </div>
  );
};

export default AppointmentScheduling;
