import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import { removeItemFromCart, changeItemAmount, setCartVisibility } from '../../store/slices/cartSlice';
import { selectCartItems, selectCartTotal } from '../../store/selectors/cart';

const CartDropdown = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const cartTotal = useSelector(selectCartTotal);

  return (
    <>
      {cartItems?.length ?  <ul className="style-none cart-product-list">
        {cartItems.map((item) => { return <li key={item._id} className="d-flex align-items-center selected-item">
          <Link
            to="/product-details"
            className="item-img d-flex align-items-center justify-content-center"
          >
            <img src={item.ingredient.image} alt="" />
          </Link>
          <div className="item-info">
            <Link to="/product-details" className="name">
              {item.ingredient.name}
            </Link>
            <div className="price">
              €{item.ingredient.price}<span className="quantity">x{item.amount}</span>
            </div>
          </div>
          {/* <!-- /.item-info --> */}
        </li>})}
        {/* <!-- /.selected-item --> */}
        {/* <!-- /.selected-item --> */}
      </ul>: <h4>No items</h4>}
      {/* <!-- /.cart-product-list --> */}

      <div className="subtotal d-flex justify-content-between align-items-center">
        <div className="title">Subtotal</div>
        <div className="total-price">€{cartTotal.toFixed(2)}</div>
      </div>
      <ul className="style-none button-group">
        <li>
          <Link to="/cart" onClick={() => dispatch(setCartVisibility(false))} className="view-cart">
            View Cart
          </Link>
        </li>
        {/* <li>
          <Link to="/checkout" className="checkout">
            Checkout
          </Link>
        </li> */}
      </ul> 
    </>
  );
};

export default CartDropdown;
