import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query'
import { Helmet } from "react-helmet";
import Header from "../../../components/e-commerce/Header";
import FooterEight from "../../../components/footer/FooterEight";
import CopyRight from "../../../components/footer/CopyRight";
import BlogDetailsForm from "../../../components/form/BlogDetailsForm";

import {useRouter} from "../../../hooks/utils";
import api from '../../../services/api/api';


const BlogDetails = () => {
  const router = useRouter()
  const blogId = router.params.id
  const blogData = useQuery(['blogs', blogId], () => api.requests.blogs.getSingleBlog(blogId));
  const blog = blogData?.data?.data?.data?.document;

  return (
    <div className="main-page-wrapper p0">
      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      <div className="fancy-hero-five bg-white">
        <img
          src="/images/shape/95.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/96.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper ">
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h6 className="page-title">Our Articles</h6>
              <h1 className="heading">
                <span>
                  {blog?.title}
                  <img src="/images/shape/line-shape-11.svg" alt="" />
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
                <img
                  src={blog?.image}
                  alt="media post"
                  className="image-meta"
                />
                <div>
                  <div className="content" dangerouslySetInnerHTML={{__html: blog?.text}}></div>
                </div>
              </div>
              {/* /End post content  */}

              {/* <div className="comment-area">
                <h3 className="title">2 Comments</h3>
                <div className="single-comment">
                  <div className="d-flex">
                    <img
                      src="/images/blog/media_30.png"
                      alt="blog post"
                      className="user-img"
                    />
                    <div className="comment">
                      <h6 className="name">Al Hasani</h6>
                      <div className="time">13 June, 2018, 7:30pm</div>
                      <p>
                        One touch of a red-hot stove is usually all we need to
                        avoid that kind of discomfort in future. The same true
                        we experience{" "}
                      </p>
                      <button className="reply">Reply</button>
                    </div>
                  </div>
                </div>
                <div className="single-comment">
                  <div className="d-flex">
                    <img
                      src="/images/blog/media_31.png"
                      alt="blog post"
                      className="user-img"
                    />
                    <div className="comment">
                      <h6 className="name">Rashed ka.</h6>
                      <div className="time">13 June, 2018, 7:30pm</div>
                      <p>
                        One touch of a red-hot stove is usually all we need to
                        avoid that kind of discomfort in future. The same true
                        we experience{" "}
                      </p>
                      <button className="reply">Reply</button>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="comment-form-section m0">
                <h3 className="title">Leave A Comment</h3>
                <p>
                  <Link to="/login">Sign in</Link> to post your comment or
                  singup if you dont have any account.
                </p>
                <div className="form-style-light">
                  <BlogDetailsForm />
                </div>

              </div> */}

            </div>

          </div>

        </div>
      </div>

      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogDetails;
