import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';


import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/main.scss";
import initStore from './store/init'

const queryClient = new QueryClient()

ReactGA.initialize("G-PWWQ75KK3Q");

const root = ReactDOM.createRoot(document.getElementById("root"));

const { store, persistor } = initStore();

root.render(
  <Provider store={store}>
     <PersistGate persistor={persistor}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
    </PersistGate>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
