import React, {useState} from "react";
import { Helmet } from "react-helmet";

import { useRouter } from '../../hooks/utils';

import BestSelling from "../../components/e-commerce/BestSelling";
import CallToAction from "../../components/e-commerce/CallToAction";
import Categorie from "../../components/e-commerce/Categorie";
import FancyBlock from "../../components/e-commerce/FancyBlock";
import FlashSaleBanner from "../../components/e-commerce/FlashSaleBanner";
import GalleryBox from "../../components/e-commerce/GalleryBox";
import Header from "../../components/e-commerce/Header";
import Hero from "../../components/e-commerce/Hero";
import TopItem from "../../components/e-commerce/TopItem";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FooterSeven from "../../components/footer/FooterSeven";
import Filters from "../../components/filters/Filters";
import FooterEight from "../../components/footer/FooterEight";
import DocSidebarContent from "../inner-pages/docs/DocSidebarContent";

const Ecommerce = () => {
    const [click, setClick] = useState(false);
     const [name, setName] = useState('');
     const router = useRouter();

  const handleClick = () => setClick(!click);

  const handleInputChange = (e) => {
    const {value} = e.target;
    setName(value);
    if (!value && router.params?.name) {
        router.push(router.pathname, { ...router.params, name: undefined });
    }
  };

   const handleSubmit = (e) => {
    e.preventDefault();
    router.push(router.pathname, { ...router.params, name });
  };


  

  
  return (
    <div className="main-page-wrapper p0 font-gordita">

            <Header />
      {/* End Page SEO Content */}

      {/* <!-- 
        =============================================
        Theme Main Menu
        ============================================== 
        --> */}
      {/* <Header /> */}

      {/* <!-- 
        =============================================
        Theme Hero Banner
        ============================================== 
        --> */}
      {/* <Hero /> */}

      {/* <!-- 
        =============================================
        Fancy Feature Forty Two
        ============================================== 
        --> */}
      {/* End .fancy-feature-fortyTwo */}

      {/* <!-- 
        =============================================
            Fancy Feature Forty Two
        ============================================== 
        --> */}

         <div className="doc-container doc-box mt-70 sm-m0">
        <div className="container">
          <div className="row flex-xl-nowrap no-gutters">
   <div className="col-xl-3 col-md-4 doc-sidebar">
              <div className="clearfix">
                <div className="mob-header">
                  <button className="toggler-menu" onClick={handleClick}>
                    <div className={click ? "active" : ""}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </button>
                </div>
                {/* End Header */}
              </div>
              <nav
                className={
                  click
                    ? "doc-links collapse clearfix nav-fix  show"
                    : "doc-links collapse clearfix nav-fix"
                }
                id="doc-sidebar-nav"
              >
                <div className="search-form">
                  <form onSubmit={handleSubmit}>
                    <input type="text" placeholder="Search here.." value={name} onChange={handleInputChange} />
                    <button>
                      <img src="images/icon/59.svg" alt="icon" />
                    </button>
                  </form>
                </div>
                <Filters/>
              </nav>
              {/* /.doc-links */}
            </div>
              <main className="col-xl-9 col-md-8 doc-main-body mt-40">
                 <h2>Recipes</h2>
                <TopItem />
              </main>

          </div>
        </div>
        </div>


        <br/>
        <hr/>
      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>

      {/* <!-- 
        =============================================
        Flash Sale Banner
        ============================================== 
		--> */}
      {/* <div className="flash-sale-banner mt-150 lg-mt-120">
        <div className="xl-container">
          <FlashSaleBanner />
        </div>
      </div> */}


      {/* <!-- 
        =============================================
        Fancy Feature Forty Four
        ============================================== 
        --> */}
      {/* <div className="fancy-feature-fortyFour lg-container mt-140 lg-mt-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 m-auto">
              <div
                className="title-style-eleven text-center mb-40 lg-mb-20"
                data-aos="fade-up"
              >
                <h2>We’r commited to provie quality services</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <FancyBlock />
          </div>
        </div>
      </div> */}

      {/* <!-- 
        =============================================
        Fancy Feature Forty Two
        ============================================== 
        --> */}
      {/* <div className="fancy-feature-fortyThree lg-container pt-120 pb-150 mt-150 lg-mt-120 lg-pt-80 lg-pb-80">
        <div className="container">
          <div className="row align-items-center" data-aos="fade-up">
            <div className="col-md-7">
              <div className="title-style-eleven text-center text-md-left">
                <h2>Best Selling</h2>
              </div>
            </div>

            <div className="col-md-5">
              <a
                href="#"
                className="shop-btn-one tran3s d-none d-md-flex align-items-center justify-content-center rounded-circle ms-auto"
              >
                <img src="images/icon/202.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>

        <div className="xl-container mt-80 lg-mt-50">
          <div className="product_slider_two product_slider_space">
            <BestSelling />
          </div>
        </div>
      </div> */}

      {/* <!--
        =====================================================
        Shop Discount Subscription
        =====================================================
        --> */}
      {/* <div className="shop-discount-subscription lg-container mt-140 mb-150 lg-mt-100 lg-mb-100">
        <CallToAction />

        <div className="xl-container mt-110 lg-mt-80" data-aos="fade-up">
          <h4 className="text-center text-dark mb-40 lg-mb-20">
            Follow Us @TrendUi
          </h4>

          <GalleryBox />
        </div>
      </div> */}
      {/* <!-- /.shop-discount-subscription --> */}

      {/* =====================================================
        Footer Style Seven
        ===================================================== */}
      {/* <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
      </footer> */}
    </div>
    // End main-page-wrapper
  );
};

export default Ecommerce;
