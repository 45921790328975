
// import { useLocation, useNavigate, useParams } from 'react-router-dom';
// import qs from 'qs';

// export const useRouter = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const params = useParams();

//   const pathname = location.pathname;
//   const state = location.state || {};
//   const canGoBack = !location.state?.fromOutside;

//   const push = (_pathname, _params, _state) => {
//     const search = qs.stringify(_params);
//     navigate({ pathname: _pathname, search, state: _state });
//   };

//   const replace = (_pathname, _params, _state) => {
//     const search = qs.stringify(_params);
//     navigate({ pathname: _pathname, search, state: _state, replace: true });
//   };

//   const goBack = () => navigate(-1);

//   return { pathname, state, params, canGoBack, push, replace, goBack };
// };


import { useLocation, useNavigate, useParams } from 'react-router-dom';
import qs from 'qs';

export const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const routeParams = useParams();
  
  const pathname = location.pathname;
  const state = location.state || {};
  const canGoBack = !location.state?.fromOutside;
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const push = (_pathname, _params, _state) => {
    const search = qs.stringify(
      Object.fromEntries(
        Object.entries(_params).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ])
      )
    );
    navigate({ pathname: _pathname, search, state: _state });
  };

  const replace = (_pathname, _params, _state) => {
    const search = qs.stringify(
      Object.fromEntries(
        Object.entries(_params).map(([key, value]) => [
          key,
          typeof value === 'object' ? JSON.stringify(value) : value,
        ])
      )
    );
    navigate({ pathname: _pathname, search, state: _state, replace: true });
  };

  const goBack = () => navigate(-1);

  return {
    pathname,
    state,
    params: {
      ...routeParams,
      ...Object.fromEntries(
        Object.entries(queryParams).map(([key, value]) => [
          key,
          (() => {
            try {
              return JSON.parse(value);
            } catch {
              return value;
            }
          })()
        ])
      )
    },
    canGoBack,
    push,
    replace,
    goBack,
  };
};
