import React from "react";
import FaqFour from "../../components/faq/FaqFour";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query'
import {useRouter} from "../../hooks/utils";
import api from '../../services/api/api';
import FooterEight from "../../components/footer/FooterEight";
import Header from "../../components/e-commerce/Header";

const Tips = ()=> {
    const { push, params } = useRouter();
  const tipsData = useQuery(['tips', params.page], () => api.requests.tips.getTipList(params));
    const tips= tipsData?.data?.data?.data?.documents

    return         <div className="main-page-wrapper">
         <Header/>
      <div className="faq-section-four tips-page">
        <img
          src="/images/shape/120.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/121.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <div className="title-style-five text-center mb-80 md-mb-60">
            <h6>TIPS</h6>
            <h2>
              <span>Expert Tips for Baby's Health and Well-being</span>
            </h2>
          </div>

          <div className="row">
            <div className="col-xl-9 col-lg-10 m-auto">
              <FaqFour tips={tips}/>
            </div>
          </div>
        </div>
        {/* /.container */}
      </div>
      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>
        </div> 
}

export default Tips;