import React from "react";

const FancyFeatureTewentyFour = () => {
  return (
    <div className="row">
      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#3FE193" }}
            >
              <img src="images/icon/126.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Time-Saving Convenience</h4>
              <p>
              Effortlessly provide nourishing meals for your baby while saving valuable time for yourself
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FF4F86" }}
            >
              <img src="images/icon/130.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Nutritional Excellence</h4>
              <p>
                Ensure your baby receives expert-curated, nutritionally balanced meals for their optimal growth and development
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFCF39" }}
            >
              <img src="images/icon/128.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Customized Options</h4>
              <p>
                Tailor each meal to your baby's preferences, allergies, and dietary needs for a personalized and enjoyable dining experience.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#6D64FF" }}
            >
              <img src="images/icon/131.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Peace of Mind</h4>
              <p>
                Rest easy knowing that your baby is receiving high-quality, wholesome meals prepared with care and expertise.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>




    </div>
  );
};

export default FancyFeatureTewentyFour;
