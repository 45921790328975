import React, {useState, useEffect} from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";


const socialContent = [
  // {
  //   icon: "fa-facebook",
  //   link: "https://www.facebook.com/",
  // },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/rekapd.io",
  },
  // {
  //   icon: "fa-pinterest-p",
  //   link: "https://www.pinterest.com/",
  // },

  // {
  //   icon: "fa-linkedin",
  //   link: "https://www.linkedin.com/",
  // },
];

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
   ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Coming soon page => " + window.location.pathname });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    sendEmail()
  };

   const sendEmail = async () => {
    try {
         if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        return
          }

          await axios.post('/api/v1/emails', {
            email
          })
        setSubmitted(true)
          setEmail("")
    } catch (error) {
      
    }
  }

  return (
    <>
      <Helmet>
        <title>
           REKAPD - Transforming Baby Mealtime with Convenience and Nutrition
        </title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="full-height-layout d-flex align-items-center">
        <div className="coming-soon-content font-gordita">
          <div className="logo coming-soon-brand header-logo">
            <Link to="/">
              <img src="images/logo/logo-svg.svg" alt="brand logo" />
            </Link>
          </div>
          <h6>COMING SOON</h6>
          <h1 data-aos="fade-up">Get notified when we’re ready to launch!</h1>
          <div className="row">
            <div className="col-lg-9 m-auto">
              <p>
                We're under construction. Check back for an update soon. Stay in
                touch!
              </p>
            </div>
          </div>

        {
          isSubmitted ?  <div className="row">
            <div className="col-lg-9 m-auto">
              <p>
                Thank you!
              </p>
            </div>
          </div> :   <form onSubmit={(event) => handleSubmit(event)}>
            <input type="email" placeholder="john@gmail.com" value={email}  onChange={(e) => setEmail(e.target.value)}/>
            <button>Notify Me</button>
          </form>
        }

        

          <ul className="social-icon d-flex justify-content-center">
            {socialContent.map((val, i) => (
              <li key={i}>
                <a href={val.link} target="_blank" rel="noreferrer">
                  <i className={`fa ${val.icon}`}></i>
                </a>
              </li>
            ))}
          </ul>

          <img
            src="images/shape/179.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/180.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <img
            src="images/shape/181.svg"
            alt="shape"
            className="shapes shape-three"
          />
          <img
            src="images/shape/182.svg"
            alt="shape"
            className="shapes shape-four"
          />
          <img
            src="images/shape/183.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <img
            src="images/shape/184.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <img
            src="images/shape/185.svg"
            alt="shape"
            className="shapes shape-seven"
          />
          <img
            src="images/shape/186.svg"
            alt="shape"
            className="shapes shape-eight"
          />
          <img
            src="images/shape/187.svg"
            alt="shape"
            className="shapes shape-nine"
          />
          <img
            src="images/shape/188.svg"
            alt="shape"
            className="shapes shape-ten"
          />
        </div>
        {/* /.coming-soon-content */}
      </div>
    </>
  );
};

export default ComingSoon;
