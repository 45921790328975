import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/e-commerce/Header";
import FancyVideoOne from "../../../../components/video/FancyVideoOne";
import AboutTabs from "../../../../components/block-slider/AboutTabs";
import CounterThree from "../../../../components/counter/CounterThree";
import TeamFive from "../../../../components/team/TeamFive";
import Faq from "../../../../components/faq/Faq";
import CallToActionFive from "../../../../components/call-to-action/CallToActionFive";
import FooterFour from "../../../../components/footer/FooterFour";
import BlogStyle5 from "../../../../components/blog/BlogStyle5";
import FooterEight from "../../../../components/footer/FooterEight";

const EventOrganizer = () => {
  return (
    <div className="main-page-wrapper p0">
      {/* End Page SEO Content */}

      <Header />
      {/* End HeaderFour */}

      {/* =============================================
				Fancy Text block Twenty
			============================================== */}
      <div className="fancy-text-block-twenty">
        <img
          src="/images/shape/124.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="/images/shape/125.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <h1 className="title font-slab">About us</h1>
          <div className="fancy-video-box-one mb-130 md-mb-70">
            <img
          src="/images/media/team.jpg"
          alt="Team"
        />
          </div>

          <div className="row">
            <div
              className="col-lg-5"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              {/* <div className="client-info font-rubik">
                Over <span>150,000+ client</span>
              </div> */}
              <div className="title-style-five">
                <h2>
                  <span>We want to simplify meal planning for parents!</span>
                </h2>
              </div>
            </div>
            <div
              className="col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <AboutTabs />
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-twenty */}

      {/* =====================================================
				Counter With Icon One
			===================================================== */}
      <div className="counter-with-icon-one">
        <div className="container">
            <div className="title-style-five text-center mb-90 md-mb-60">
            <h6>About Us</h6>
            <h2>What we do</h2>
          </div>
          <div>
            <CounterThree />
          </div>
        </div>
      </div>
      {/* /.counter-with-icon-one */}

      {/* =============================================
				Team Section
			==============================================  */}
      <div className="team-section-four mt-250 md-mt-150">
        <img
          src="images/shape/129.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/130.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="container">
          <div className="title-style-five text-center mb-90 md-mb-60">
            <h6>Our Team</h6>
            <h2>The team behind REKAPD </h2>
          </div>
           <p className="font-rubik team-desc">
We are Regina, Kathrin, Phoebe  and Doniyor. 
Newbie entrepreneurs with multicultural backgrounds and thrilled to make a change for young parents introducing their babies to solid foods!
Our multifarious team of nutrition professionals, midwives, and educators understands the significance of different dietary practices in the introduction of solid foods, aiming to create a nourishing and balanced food environment for children. We are committed to helping parents navigate this exciting phase with their little ones.
          </p>
          <br />
          <div className="team-wrapper">
            <div className="row">
              <TeamFive />
            </div>
            <img
              src="images/shape/126.svg"
              alt="shape"
              className="shapes shape-one"
            />
          </div>
          {/*  /.team-wrapper */}
        </div>
      </div>
      {/* /.team-section-four */}

      {/* 
     =============================================
				Fqa Text Block
		============================================== */}
      {/* <div className="fancy-text-block-six mt-250 pt-50 md-mt-130">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div className="title-style-five mb-35">
                <h6>Why choose us</h6>
                <h2>
                  <span>Why you should</span> choose us?
                </h2>
              </div>
              <Faq />
            </div>

            <div
              className="col-lg-6 col-md-8 m-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="illustration-holder d-flex justify-content-end md-mt-50">
                <img src="images/media/img_58.png" alt="media" />
                <img
                  src="images/shape/113.svg"
                  alt="media"
                  className="shapes shape-one"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* =====================================================
				Feature Blog Four
			===================================================== */}
      {/* <div className="feature-blog-four mt-250 md-mt-200">
        <div className="container">
          <div className="title-style-five text-center mb-70 md-mb-50">
            <h6>News & Updates</h6>
            <h2>Latest news & articles</h2>
          </div>
          <div className="row">
            <BlogStyle5 />
          </div>
        </div>
      </div> */}

      {/* <div className="fancy-short-banner-five pt-150 pb-150 md-pt-100 md-pb-100">
        <div className="container">
          <CallToActionFive />
        </div>
      </div> */}

      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            {/* <CopyRightFour /> */}
          </div>
        </div>
      </footer>
      {/* <footer className="theme-footer-four">
        <div className="top-footer">
          <div className="container">
            <FooterFour />
          </div>
        </div>

        <div className="container">
          <div className="bottom-footer-content">
            <p>
              Copyright @{new Date().getFullYear()}{" "}
              <a
                href="https://themeforest.net/user/ib-themes/portfolio"
                target="_blank"
                rel="noreferrer"
              >
                ib-themes
              </a>
              inc.
            </p>
          </div>
        </div>
      </footer> */}
    </div>
  );
};

export default EventOrganizer;
