import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { removeItemFromCart, changeItemAmount } from '../../../store/slices/cartSlice';
import { selectCartItems, selectCartTotal } from '../../../store/selectors/cart';
import CartProduct from "../../../components/e-commerce/cart/CartProduct";
import CartTotal from "../../../components/e-commerce/cart/CartTotal";
import Coupon from "../../../components/e-commerce/cart/Coupon";
import Header from "../../../components/e-commerce/Header";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);
  const cartTotal = useSelector(selectCartTotal);

  console.log("cartItems => ", cartItems);

  const handleRemoveFromCart = itemId => {
    dispatch(removeItemFromCart(itemId));
  };


  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div className="main-page-wrapper p0 font-gordita">
      {/* End Page SEO Content */}

      {/* <!-- 
    =============================================
    Theme Main Menu
    ============================================== 
    --> */}
      <Header />

      {/* <!-- 
      =============================================
      Cart Page
      ============================================== 
      --> */}
      <div className="cart-section pt-200 pb-100 lg-pt-180 sm-pb-50">
        <div className="container">
          <form className="cart-list-form" onClick={handleSubmit}>
            <div className="table-responsive">
             {cartItems?.length ? <table className="table">
                <thead>
                  <tr>
                    <th colSpan="2">Product</th>
                    <th>Price</th>
                    <th>QTY</th>
                    <th>Total</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                {cartItems.map((item) => {
                  return <tr key={item?._id}><td className="product-thumbnails">
                <a href="#" className="product-img">
                  <img src={item.ingredient?.image} alt="" />
                </a>
              </td>
              <td className="product-info">
                <a href="#" className="product-name">
                  {item.ingredient.name}
                </a>
                <div className="serial">{item.ingredient?.category?.name}</div>
                <ul className="style-none">
                  <li className="size">Weight: {item.ingredient.quantity}{item.ingredient.measurement}</li>
                </ul>
              </td>
              <td className="price">
                <span>€{item.ingredient.price.toFixed(2)}</span>
              </td>
              <td className="quantity">
                <ul className="order-box style-none">
                  <li>
                    <div className="btn value-decrease" onClick={() =>  parseInt(item.amount) > 1 && dispatch(changeItemAmount({ itemId: item._id, amount: parseInt(item.amount) - 1 }))}>-</div>
                  </li>
                  <li>
                    <input
                      type="number"
                      min="1"
                      max="22"
                      disabled
                      value={item.amount}
                      className="product-value val"
                    />
                  </li>
                  <li>
                    <div className="btn value-increase" onClick={() =>  dispatch(changeItemAmount({ itemId: item._id, amount: parseInt(item.amount) +1 }))}>+ </div>
                  </li>
                </ul>
              </td>
              <td className="price total-price">
                <span>€{(item.amount * item.ingredient?.price).toFixed(2)}</span>
              </td>
              <td>
                <a href="#" className="remove-product" onClick={() => handleRemoveFromCart(item.ingredient._id)}>
                  x
                </a>
              </td>
            </tr>
                })}
                </tbody>
              </table> : <h4>No items</h4>}
            </div>
            {/* <!-- /.table-responsive --> */}

            <div className="d-sm-flex justify-content-between cart-footer">
              {/* <Coupon /> */}
              <div></div>
              {/* <!-- /.coupon-section --> */}
{cartItems?.length ?
              <div className="cart-total-section d-flex flex-column sm-pt-40">
                 <table className="cart-total-table">
        <tbody>
          <tr>
            <th>Subtotal</th>
            <td>€{cartTotal.toFixed(2)}</td>
          </tr>
          <tr>
            <th>Shipping Cost</th>
            <td>€5.00</td>
          </tr>
          <tr>
            <th>Total</th>
            <td>€{(cartTotal + 5).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      {/* <!-- /.cart-total-table --> */}
      <Link to="/coming-soon" className="theme-btn-seven checkout-process mt-30">
        Checkout
      </Link>
              </div> : null}
            </div>
            {/* <!-- /.cart-footer --> */}
          </form>
        </div>
        {/* <!-- /.container --> */}
      </div>
      {/* <!-- /.cart-section --> */}
    </div>
  );
};

export default Cart;
