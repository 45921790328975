import classNames from "classnames";

const ListOptions = ({
    items,
    selectedItemIds,
    idLabel,
    titleLabel,
    onSelect
}) => {
    return <div className="list-options-container">
        <div className="list-options">
            {items.map(item => {
                return <div key={item[idLabel]} className={classNames('list-option', { selected: selectedItemIds.includes(item[idLabel])}) } onClick={() => onSelect(item[idLabel])}>
                <p className="font-rubik">
                    {item[titleLabel]}
                </p>
            </div>
            })}
        </div>
    </div>
}

export default ListOptions;