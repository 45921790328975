import React from "react";

const Quantity = ({
    value,
    onMinus,
    onPlus,
    onChange
}) => {
  return (
    <div className="button-group">
      <ul className="style-none d-flex align-items-center">
        <li>
          <button className="value-decrease" onClick={() => onMinus()}>-</button>
        </li>
        <li>
          <input
            type="number"
            min="1"
            max="22"
            value={value}
            onChange={onChange}
            className="product-value val"
          />
        </li>
        <li>
          <button className="value-increase" onClick={() => onPlus()}>+ </button>
        </li>
      </ul>
    </div>
  );
};

export default Quantity;
