import React from "react";
import { Link } from "react-router-dom";

const TeamContent = [
  {
    designation: "Director of Operations",
    name: "Regina",
    img: "regina",
    animationDelay: "200",
  },
  {
    designation: "Managing Director",
    name: "Kathrin",
    img: "kathrin",
    animationDelay: "250",
  },
  {
    designation: "Creative Director",
    name: "Phoebe",
    img: "phoebe",
    animationDelay: "300",
  },
  {
    designation: "Technical Director",
    name: "Doniyor",
    img: "doniyor",
    animationDelay: "350",
  },
];

const TeamFive = () => {
  return (
    <>
      {TeamContent.map((item, i) => (
        <div
          className="col-lg-3 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={item.animationDelay}
          key={i}
        >
          <div className="team-member">
            <div className="img-holder">
              <img src={`images/media/${item.img}.jpg`} alt="team" />
            </div>
            <h6 className="name">{item.name}</h6>
            <div className="position">{item.designation}</div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TeamFive;
