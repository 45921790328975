import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: { items: [], isVisible: false },
  reducers: {
    addItemToCart(state, action) {
      const item = action.payload;
      const existingItem = state.items.find((cartItem) => cartItem.ingredient._id === item.ingredient._id);

      if (existingItem) {
        // Item with the same _id already exists in the cart, update the amount
        existingItem.amount += item.amount;
      } else {
        // Item doesn't exist in the cart, add it
        state.items.push(item);
      }
    },
    addMultipleItemsToCart(state, action) {
      const items = action.payload;

      items.forEach((item) => {
        const existingItem = state.items.find((cartItem) => cartItem.ingredient._id === item.ingredient._id);

        if (existingItem) {
          // Item with the same _id already exists in the cart, update the amount
          existingItem.amount += item.amount;
        } else {
          // Item doesn't exist in the cart, add it
          state.items.push(item);
        }
      });
    },
    removeItemFromCart(state, action) {
      const itemId = action.payload;
      const index = state.items.findIndex((cartItem) => cartItem.ingredient._id === itemId);

      if (index !== -1) {
        // Item found in the cart, remove it
        state.items.splice(index, 1);
      }
    },
    changeItemAmount(state, action) {
      const { itemId, amount } = action.payload;
      const item = state.items.find((cartItem) => cartItem._id === itemId);

      if (item) {
        // Item found in the cart, update the amount
        item.amount = amount;
      }
    },
    toggleCartVisibility(state) {
      state.isVisible = !state.isVisible;
    }, 
    setCartVisibility(state, action) {
      state.isVisible = action.payload;
    },
  },
});

export const {
  addItemToCart,
  addMultipleItemsToCart,
  removeItemFromCart,
  changeItemAmount,
  toggleCartVisibility,
  setCartVisibility
} = cartSlice.actions;

export default cartSlice.reducer;
