import axios from 'axios';

import config from './config';
import qs from 'qs';

const request = axios.create({
  baseURL: config.URL
});

const subscribe = globalState => {
  const { dispatch } = globalState;
  const { token } = globalState && globalState.state && globalState.state.auth;

  request.interceptors.request.use(config => {
    if (token) {
      config.headers['Authorization'] = ['Bearer', token].join(' ');
    }

    return config;
  });

  request.interceptors.response.use(
    config => config,
    error => {
      if (error && error.response && error.response.status === 401) {
        dispatch({ type: 'logout' });
      }

      throw error;
    }
  );
};

const requests = {
  auth: {
    login: id => request.get(`/directory/stores/by-company/${id}`)
  },
  users: {
    getUserList: role => request.get(`/users${qs.stringify({ role }, { addQueryPrefix: true })}`),
    getSingleUser: id => request.get(`/users/${id}`),
    createUser: data => request.post(`/users`, data),
    updateUser: data => request.patch(`/users/${data.id}`, data.data)
  },
  phases: {
    getPhaseList: () => {
      return request.get(`/phases`);
    },
    // createTransaction: data => request.post(`/transactions`, data),
    // updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    // deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
  forms: {
    getFormList: () => {
      return request.get(`/forms`);
    },
    // createTransaction: data => request.post(`/transactions`, data),
    // updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    // deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
  allergens: {
    getAllergenList: () => {
      return request.get(`/allergens`);
    },
    // createTransaction: data => request.post(`/transactions`, data),
    // updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    // deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
  ingredients: {
    getIngredientList: () => {
      return request.get(`/ingredients`);
    },
    // createTransaction: data => request.post(`/transactions`, data),
    // updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    // deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
  recipes: {
    getRecipeList: (filters) => {
      console.log("filters => ", filters);
      const query = qs.stringify(filters, { addQueryPrefix: true });
      return request.get(`/recipes${query}`);
    },
    getSingleRecipe: (id) => {
      return request.get(`/recipes/${id}`);
    },
    // createTransaction: data => request.post(`/transactions`, data),
    // updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    // deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
    blogs: {
    getBlogList: (filters) => {
      const query = qs.stringify(filters, { addQueryPrefix: true });
      return request.get(`/blogs${query}`);
    },
    getSingleBlog: (id) => {
      return request.get(`/blogs/${id}`);
    },
    // createTransaction: data => request.post(`/transactions`, data),
    // updateTransaction: data => request.patch(`/transactions/${data.id}`, data.data),
    // deleteTransaction: id => request.delete(`/transactions/${id}`)
  },
  tips: {
    getTipList: (filters) => {
      const query = qs.stringify(filters, { addQueryPrefix: true });
      return request.get(`/tips${query}`);
    },
  }

};

export default { request, subscribe, requests };
