import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import api from '../../services/api/api';
import {useRouter} from "../../hooks/utils";
import { Link } from "react-router-dom";
import { useQuery } from 'react-query'
import { addMultipleItemsToCart, setCartVisibility } from '../../store/slices/cartSlice';
import { selectCartVisibility} from '../../store/selectors/cart';

const TopItem = () => {
  const dispatch = useDispatch();
  const { params } = useRouter();
  const recipesData = useQuery(['recipes', params], () => api.requests.recipes.getRecipeList(params));
  const isCartVisible = useSelector(selectCartVisibility);

  const recipes = recipesData?.data?.data?.data?.documents

  const handleAddToCart = (recipe) => {
    dispatch(addMultipleItemsToCart(recipe.ingredients));

    if (!isCartVisible) {
        dispatch(setCartVisibility(true));
    }
  };

  const settings = {
    dots: false,
    arrows: false,
    centerPadding: "0px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const topItemContent = [
    {
      id: 1,
      img: "img_08",
      tag: "hot",
      title: "Quilted Gilet With Hood",
      price: "17.99",
      ratings: (
        <>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star-o" aria-hidden="true"></i>
          </li>
        </>
      ),
    },
    {
      id: 2,
      img: "img_09",
      tag: "best seller",
      title: " Jogers with Black strip",
      price: "80.99",
      ratings: (
        <>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star-o" aria-hidden="true"></i>
          </li>
        </>
      ),
    },
    {
      id: 3,
      img: "img_10",
      tag: "trending",
      title: "Rolex Gold Watch",
      price: "217.99",
      ratings: (
        <>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star-o" aria-hidden="true"></i>
          </li>
        </>
      ),
    },
    {
      id: 4,
      img: "img_11",
      tag: "hot",
      title: "Men’s Nike T-Shirt",
      price: "23.99",
      ratings: (
        <>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star-o" aria-hidden="true"></i>
          </li>
        </>
      ),
    },
    {
      id: 5,
      img: "img_12",
      tag: "best sellter",
      title: "Quilted Gilet With Hood",
      price: "17.99",
      ratings: (
        <>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star-o" aria-hidden="true"></i>
          </li>
        </>
      ),
    },
  ];

  return (
    <>
      {/* <Slider {...settings}> */}
        <div className="products-container"> 

        {recipesData.isLoading && Array.from({ length: 9 }, (_, i) => {
          return   <div className="product-item" key={i}>
            <div className="product-block-one">
              <div className="img-holder">
                {/* <div className="tag-one">{item.phase?.name}</div>
                <div className="tag-one">{item.form?.name}</div> */}
              </div>
              {/* <!-- /.img-holder --> */}

 
              <span class="placeholder col-7"></span>
              <br />
      <span class="placeholder col-4"></span>
       <br />
      <span class="placeholder col-4"></span>
           <span class="placeholder col-4"></span>
                {/* <div className="price">$5.99</div> */}
              {/* <!-- /.product-meta --> */}
            </div>
            {/* <!-- /.product-block-one --> */}
          </div>
        })}
        {!recipesData.isLoading && recipes?.map((item) => (
          <div className="product-item" key={item._id}>
            <div className="product-block-one">
              <div className="img-holder">
                <Link
                  to={`/recipes/${item._id}`}
                  className="d-flex align-items-center justify-content-center h-100"
                >
                  <img
                    src={item.image}
                    alt="shop product"
                    className="product-img tran4s"
                  />
                </Link>
                <a onClick={() => handleAddToCart(item)}  className="cart-icon" title="Add To Cart">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                </a>
                {/* <div className="tag-one">{item.phase?.name}</div>
                <div className="tag-one">{item.form?.name}</div> */}
              </div>
              {/* <!-- /.img-holder --> */}

              <div className="product-meta">
                <div className="d-lg-flex align-items-center justify-content-between">
<div>{item.phase?.name}</div>
<div className="ta-right">{item.form?.name}</div>
                  </div>
<Link to={`/recipes/${item._id}`} className="product-title">
                    {item.name}
                  </Link>
                <div className="d-lg-flex align-items-center justify-content-between">
                  <div>
                          
                  <ul className="style-none d-flex rating"> <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star" aria-hidden="true"></i>
          </li>
          <li>
            <i className="fa fa-star-o" aria-hidden="true"></i>
          </li></ul>
                  </div>

                </div>
                {/* <div className="price">$5.99</div> */}
              </div>
              {/* <!-- /.product-meta --> */}
            </div>
            {/* <!-- /.product-block-one --> */}
          </div>
        ))}
        {!recipesData.isLoading && !recipes.length && <h4>No Recipes Found!</h4>}
         {/* {topItemContent.map((item) => (
          <div className="product-item" key={item.id}>
            <div className="product-block-one">
              <div className="img-holder">
                <Link
                  to="/product-details"
                  className="d-flex align-items-center justify-content-center h-100"
                >
                  <img
                    src={`images/shop/${item.img}.png`}
                    alt="shop product"
                    className="product-img tran4s"
                  />
                </Link>
                <Link to="/cart" className="cart-icon" title="Add To Cart">
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                </Link>
                <div className="tag-one">{item.tag}</div>
              </div>


              <div className="product-meta">
                <div className="d-lg-flex align-items-center justify-content-between">
                  <Link to="/product-details" className="product-title">
                    {item.title}
                  </Link>
                  <ul className="style-none d-flex rating">{item.ratings}</ul>
                </div>
                <div className="price">$17.99</div>
              </div>
            </div>
          </div>
        ))} */}
        </div>

    </>
  );
};

export default TopItem;
