import React from "react";

const FeatureContent = [
  {
    icon: "121",
    title: "Time Constraints and Meal Planning",
    // subTitle: `Busy parents struggle with limited time to plan and prepare nutritious meals for their babies`,
    subTitle: `Limited time for planning and preparing nutritious baby meals.`,
    bgColor: "#FF4A8B",
    cta: "Save time, nourish your baby!",
        ctaLink: "/problem-1"
  },
  {
    icon: "122",
    title: "Nutrition Knowledge Gap",
    // subTitle: `Parents face challenges in understanding and meeting their baby's nutritional needs`,
    subTitle: `Lack of expertise in meeting baby's nutritional needs`,
    bgColor: "#6D49FF",
    cta: "Unlock expert nutrition guidance!",
        ctaLink: "/problem-2"
  },
  {
    icon: "123",
    title: "Limited Access to Quality Ingredients",
    // subTitle: `Parents find it difficult to source high-quality ingredients for their baby's meals`,
    subTitle: `Difficulty in accessing high-quality ingredients for baby meals`,
    bgColor: "#FFB951",
    cta: "Discover premium ingredients now!",
    ctaLink: "/problem-3"
  },
];

const FancyFeatureTewentyTwo = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-style-twentyTwo">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle} <br/>
            <a href={val.ctaLink}>{val.cta}</a></p>
          </div>
          {/* /.block-style-twentyTwo */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureTewentyTwo;
