import React, {useState} from "react";
import axios from "axios";

const FormEvent = () => {
    const [email, setEmail] = useState("");
    const [isSubmitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    sendEmail()
  };

   const sendEmail = async () => {
    try {
         if (!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) {
        return
          }

          await axios.post('/api/v1/emails', {
            email
          })
        setSubmitted(true)
          setEmail("")
    } catch (error) {
      
    }
  }

  if (isSubmitted) {
    return <div className="row">
            <div className="col-lg-9 m-auto">
              <p>
                Thank you!
              </p>
            </div>
          </div>
  }

  return (
    <form onSubmit={handleSubmit}>
      <input type="email" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
      <button type="submit">Notify Me</button>
    </form>
  );
};

export default FormEvent;
